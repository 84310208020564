import { TextOnImageTeaser as ChameleonTextOnImageTeaser } from '@mediahuis/chameleon-react';
import { ComponentType, FC, ReactElement } from 'react';
import cx from 'classnames';

import { TextOnImageTeaserProps as ChameleonTextOnImageTeaserProps } from '../../domain/chameleon/text-on-image-teaser-props';
import { TextOnImageTeaserPlaceholderProps } from './types';
import styles from './text-on-image-teaser-placeholder.module.scss';
import { mapImageFormatToAspectRatio } from '../TextOnImageTeaser/mapImageFormatToAspectRatio';

// eslint-disable-next-line react/jsx-no-useless-fragment
const EmptyImage: FC = () => <></>;

// TODO: Chameleon might create this instead
const TextOnImageTeaserPlaceholder: FC<TextOnImageTeaserPlaceholderProps> = ({
  imageFormat,
  hasDuration,
  hasLabel,
  hasIntro,
  headerLevel,
  ...props
}) => {
  const teaserImage: ReactElement = <EmptyImage />;

  const imageAspectRatio = mapImageFormatToAspectRatio(imageFormat);

  const Component = ChameleonTextOnImageTeaser as ComponentType<ChameleonTextOnImageTeaserProps>;
  return (
    <Component
      articleType="article"
      title="　"
      introduction={hasIntro ? '　' : ''}
      link=""
      label={hasLabel ? '　' : ''}
      subLabel={hasLabel ? '　　' : ''}
      duration={hasDuration ? '　　' : ''}
      {...props}
      imageOptions={{ aspectRatio: imageAspectRatio }}
      image={teaserImage}
      titleTagName={`h${headerLevel}` as ChameleonTextOnImageTeaserProps['titleTagName']}
      className={cx(styles.teaserPlaceholder, { [styles.withLabel]: hasLabel })}
    />
  );
};

export default TextOnImageTeaserPlaceholder;
